import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 210px;

  @media screen and (max-width: 1000px) {
    padding: 40px 105px;
  }


  @media screen and (max-width: 500px) {
    padding: 20px 30px;
  }
`

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 108px;
  width: 100%;
  gap: 8px;
  margin-bottom: 60px;

  @media screen and (max-width: 500px) {
    margin-bottom: 30px;
    margin-top: 50px;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`